<template>
	<!-- 스케줄 등록 팝업 -->
	<div :class="schedulePopLayer" id="layer03">
		<div class="inner" style="width:400px">
			<strong class="tit">스케줄 등록</strong>
			<div class="cont">
				<!-- inputList -->
				<div class="inputList v2">
					<dl>
						<dt>스케줄명</dt>
						<dd>
							<input
								type="text"
								title="스케줄명"
								placeholder="스케줄명을 입력해주세요"
								v-model="schedule.name"
								maxlength="254"
							/>
						</dd>
					</dl>
					<dl>
						<dt>주기종류</dt>
						<dd>
							<select v-model="schedule.intervalType" @change="setInterval()" title="주기종류 선택">
								<option value="Y">매년</option>
								<option value="M">매월</option>
								<option value="W">매주</option>
								<option value="D">지정일</option>
							</select>
						</dd>
					</dl>
					<dl>
						<dt>시작일자</dt>
						<dd>
							<DatePicker
								ref="startPicker"
								v-model="schedule.beginDate"
								valueType="format"
								format="YYYY-MM-DD"
								:confirm="false"
								:editable="false"
								:clearable="false"
								placeholder="날짜선택"
								:disabled-date="disableBeginDate"
								confirm-text="확인"
								@change="handlePicker()"
							/>
						</dd>
					</dl>
					<dl v-if="schedule.intervalType != 'D'">
						<dt>종료일자</dt>
						<dd>
							<DatePicker
								ref="endPicker"
								v-model="schedule.endDate"
								valueType="format"
								format="YYYY-MM-DD"
								:confirm="false"
								:editable="false"
								:clearable="false"
								placeholder="날짜선택"
								:disabled-date="disableEndDate"
								confirm-text="확인"
							/>
						</dd>
					</dl>
				</div>
				<!--// inputList -->
				<!-- btnArea -->
				<div class="btnArea">
					<button type="button" class="btn btnGray" @click="onCloseSchedule">취소</button>
					<button type="button" class="btn" :disabled="!schedule.name" @click="setSchedule">등록</button>
				</div>
				<!--// btnArea -->
			</div>
			<a href="javascript:void(0)" name="layer01" @click="onCloseSchedule">닫기</a>
		</div>
	</div>
	<!-- 스케줄 등록 팝업 -->
</template>
<script>
import 'vue2-datepicker/index.css';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ko';
export default {
	components: {
		DatePicker: DatePicker,
	},
	data() {
		return {
			schedulePopLayer: 'layerPop schedulePop',
			schedule: {
				id: null,
				name: null,
				beginDate: this.$moment().format('YYYY-MM-DD'),
				endDate: this.$moment(this.beginDate)
					.add(1, 'year')
					.format('YYYY-MM-DD'),
				intervalType: 'Y',
			},
		};
	},
	methods: {
		async onOpenSchedule(schedule) {
			if (schedule) {
				// 수정 시 스케줄이 있는 경우
				this.schedule = Object.assign({}, schedule);
			} else {
				// 등록 시 스케줄이 없는 경우 초기화
				this.schedule.name = null;
				this.schedule.beginDate = this.$moment().format('YYYY-MM-DD');
				this.schedule.endDate = this.$moment(this.beginDate)
					.add(1, 'year')
					.format('YYYY-MM-DD');
				this.schedule.intervalType = 'Y';
			}

			this.schedulePopLayer = 'layerPop schedulePop on';
		},
		onCloseSchedule() {
			this.schedulePopLayer = 'layerPop schedulePop';
		},
		// 주기 설정
		setInterval() {
			let interval = 'year';
			// switch (this.schedule.intervalType) {
			// 	case 'Y':
			// 		interval = 'year';
			// 		break;
			// 	case 'M':
			// 		interval = 'month';
			// 		break;
			// 	case 'W':
			// 		interval = 'week';
			// 		break;
			// }
			this.schedule.endDate = this.$moment(this.schedule.beginDate)
				.add(1, interval)
				.format('YYYY-MM-DD');
		},
		// 시작일 데이터픽커 핸들링
		handlePicker() {
			let interval = 'year';
			switch (this.schedule.intervalType) {
				case 'Y':
					interval = 'year';
					break;
				case 'M':
					interval = 'month';
					break;
				case 'W':
					interval = 'week';
					break;
			}
			if (this.$moment(this.schedule.endDate).diff(this.$moment(this.schedule.beginDate), interval + 's') < 1) {
				this.schedule.endDate = this.$moment(this.schedule.beginDate)
					.add(1, interval)
					.format('YYYY-MM-DD');
			}
		},
		// 시작일 데이트픽커 disabled days 설정
		disableBeginDate(date) {
			let followingDay = new Date(this.$moment().add(-1, 'days'));
			return date < followingDay;
		},
		// 종료일 데이트픽커 disabled days 설정
		disableEndDate(date) {
			let interval = 'year';
			switch (this.schedule.intervalType) {
				case 'Y':
					interval = 'year';
					break;
				case 'M':
					interval = 'month';
					break;
				case 'W':
					interval = 'week';
					break;
			}
			let followingDay = this.$moment(this.schedule.beginDate)
				.add(1, interval)
				.add(-1, 'day')
				.format('YYYY-MM-DD');
			return date < new Date(followingDay);
		},
		// 스케줄 지정
		setSchedule() {
			if (this.schedule.intervalType == 'D') {
				this.schedule.endDate = this.schedule.beginDate;
			}
			this.$emit('setSchedule', Object.assign({}, this.schedule));
			this.schedulePopLayer = 'layerPop schedulePop';
		},
	},
};
</script>

<style scoped></style>
