<template>
	<div :class="locationPopLayer" id="layer01">
		<div class="inner" style="width:400px">
			<strong class="tit">위치 선택</strong>
			<div class="cont">
				<!-- inputList -->
				<div class="inputList v2">
					<dl>
						<dt>층</dt>
						<dd>
							<select title="층 선택" @change="changeFloor" v-model="floorId">
								<option
									v-for="floor in floors"
									:value="floor.id"
									:key="floor.floors"
									:selected="floor.floors == 1 ? true : false"
									>{{ floor.name }}</option
								>
							</select>
						</dd>
					</dl>
					<dl v-if="disabledFloorPlan">
						<dt>도면</dt>
						<dd>
							<select title="도면 선택" @change="changeFloorPlan" :disabled="!disabledFloorPlan" v-model="floorPlanId">
								<option :value="null">도면 선택</option>
								<option v-for="floorPlan in floorPlans" :value="floorPlan.id" :key="floorPlan.id">{{
									floorPlan.name
								}}</option>
							</select>
						</dd>
					</dl>
					<dl v-if="disabledRoom">
						<dt>실</dt>
						<dd>
							<select title="실 선택" @change="changeRoom" :disabled="!disabledRoom" v-model="roomId">
								<option :value="null">실 선택</option>
								<option v-for="room in rooms" :value="room.id" :key="room.id">{{ room.name }}</option>
							</select>
						</dd>
					</dl>
					<dl v-if="disabledObjectPin">
						<dt>오브제핀</dt>
						<dd>
							<div class="flex">
								<input
									type="text"
									:value="objectPin && objectPin.objectName ? objectPin.objectName : '오브제 선택'"
									readonly
								/>
								<button type="button" class="btn" :disabled="!disabledObjectPin" @click="selectObjectLocation">
									선택
								</button>
							</div>
						</dd>
					</dl>
				</div>
				<!--// inputList -->
				<!-- btnArea -->
				<div class="btnArea">
					<button type="button" class="btn btnGray" @click="onCloseLocation">취소</button>
					<button type="button" class="btn" @click="addLocation" :disabled="!disabledAddLocation">확인</button>
				</div>
				<!--// btnArea -->
			</div>
			<a href="javascript:void(0)" name="layer01" @click="onCloseLocation">닫기</a>
		</div>
		<PopupObjectPin ref="popupObjectPin" />
	</div>
</template>
<script>
import floor from '@/api/floor';
import floorPlan from '@/api/floorplan';
import room from '@/api/room';
import objectPin from '@/api/objectpin';
import PopupObjectPin from '../popup/popupObjectPin';
export default {
	components: { PopupObjectPin },
	props: {
		propsBuildingId: Number,
		propsFloorId: Number,
		propsRoomId: Number,
		propsObjectPinId: Number,
	},
	data() {
		return {
			tabInfo: [
				{ order: 0, code: 'FRPLBASC', name: '기본평면도' },
				{ order: 1, code: 'FRPLELEC', name: '전기도면' },
				{ order: 2, code: 'FRPLFIRE', name: '소방도면' },
				{ order: 3, code: 'FRPLHYPL', name: '위생/배관' },
			],
			locationPopLayer: 'layerPop locationPop',
			location: {},
			buildingId: null,
			floorId: null,
			floorPlanId: null,
			roomId: null,
			objectPinId: null,
			floors: null,
			floorPlans: null,
			rooms: null,
			objectPins: null,
			objectPin: null,
		};
	},
	computed: {
		disabledAddLocation() {
			return this.floorId ? true : false;
		},
		disabledFloorPlan() {
			return this.floorPlans ? true : false;
		},
		disabledRoom() {
			return this.rooms ? true : false;
		},
		disabledObjectPin() {
			return this.objectPins ? true : false;
		},
	},
	methods: {
		async getFloor() {
			this.buildingId = this.$props.propsBuildingId;
			let params = {
				buildingId: this.buildingId,
			};
			try {
				let res = await floor.get(params);
				this.floors = res.data;
				this.floorId = this.$props.propsFloorId ? this.$props.propsFloorId : this.floors.find(f => f.floors == 1).id;
				await this.getFloorPlan();
			} catch (ex) {
				return await this.$alert(ex.message);
			}
		},
		async getFloorPlan() {
			let params = {
				floorId: this.floorId,
			};
			try {
				let res = await floorPlan.get(params);
				this.floorPlans = res.data;
				if (this.floorPlans) {
					this.floorPlans.forEach(floorPlan => {
						this.tabInfo.forEach(info => {
							if (floorPlan.type == info.code) {
								floorPlan.name = info.name;
								floorPlan.order = info.order;
							}
						});
					});
				}
				if (this.floorPlans) {
					this.floorPlans = this.floorPlans.sort((a, b) => a.order - b.order);
					this.floorPlanId = this.floorPlans ? this.floorPlans[0].id : null;
				} else {
					this.floorPlanId = null;
					this.rooms = null;
					this.roomId = null;
					return;
				}
				await this.getRoom();
			} catch (ex) {
				return await this.$alert(ex.message);
			}
		},
		async getRoom() {
			let params = {
				floorPlanId: this.floorPlanId,
			};
			try {
				let res = await room.get(params);
				this.rooms = res.data;
			} catch (ex) {
				return await this.$alert(ex.message);
			}
		},
		async getObjectPin() {
			let params = {
				roomId: this.roomId,
			};
			try {
				let res = await objectPin.get(params);
				this.objectPins = res.data;
			} catch (ex) {
				return await this.$alert(ex.message);
			}
		},
		async changeFloor() {
			this.rooms = null;
			this.roomId = null;
			this.objectPin = null;
			this.objectPinId = null;
			this.objectPins = null;
			await this.getFloorPlan();
		},
		async changeFloorPlan() {
			this.rooms = null;
			this.roomId = null;
			this.objectPin = null;
			this.objectPinId = null;
			this.objectPins = null;
			if (!this.floorPlanId) {
				return;
			}
			await this.getRoom();
		},
		async changeRoom() {
			this.objectPin = null;
			this.objectPinId = null;
			this.objectPins = null;
			if (!this.roomId) {
				return;
			}
			await this.getObjectPin();
		},
		async addLocation() {
			if (this.floorId) {
				this.location.floor = this.floors.find(f => f.id == this.floorId);
			}

			if (this.roomId) {
				this.location.room = this.rooms.find(r => r.id == this.roomId);
				if (this.location.room.shapeType === 'all' && !this.objectPin) {
					this.location = {};
					return await this.$alert('층의 오브제핀을 선택해 주세요.');
				}
			}

			if (this.objectPinId) {
				this.location.objectPin = this.objectPin;
			}

			this.$emit('selectLocation', this.location);
			this.onCloseLocation();
			this.location = {};
		},
		async onOpenLocation() {
			await this.getFloor();
			this.locationPopLayer = 'layerPop locationPop on';
		},
		onCloseLocation() {
			this.floors = null;
			this.rooms = null;
			this.objectPins = null;
			this.floorPlanId = null;
			this.floorId = null;
			this.roomId = null;
			this.ojbectPinId = null;
			this.objectPin = null;
			this.locationPopLayer = 'layerPop locationPop';
		},
		async selectObjectLocation() {
			try {
				let res = await this.$refs.popupObjectPin.select(this.roomId);
				if (res) {
					this.objectPin = res;
					this.objectPinId = res.id;
				}
			} catch (ex) {
				return this.$alert(ex);
			}
		},
	},
};
</script>
<style scoped></style>
