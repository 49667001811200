<template>
	<!-- 팝업 -->
	<div class="layerPop alert on" v-if="dialog">
		<div class="inner" style="width:500px">
			<strong class="tit">오브제핀 선택</strong>
			<div class="cont">
				<div ref="floorPlanArea" style="position: relative; width:100%; height: 400px; overflow: hidden">
					<img
						v-if="imageUrl"
						:style="{ position: 'absolute', top: `${roomData.offsetY}px`, left: `${roomData.offsetX}px` }"
						:src="imageUrl"
						:width="roomData.imageWidth * roomData.scale"
						:height="roomData.imageHeight * roomData.scale"
						alt=""
					/>
					<svg
						v-if="imageUrl"
						xmlns="http://www.w3.org/2000/svg"
						version="1.2"
						baseProfile="tiny"
						class="svg"
						ref="svgViewer"
						width="100%"
						height="100%"
						style="left: 0px; top: 0px"
					>
						<g>
							<rect
								v-if="'rect' === roomData.type"
								:x="roomData.x * roomData.scale + roomData.offsetX"
								:y="roomData.y * roomData.scale + roomData.offsetY"
								:width="roomData.width * roomData.scale"
								:height="roomData.height * roomData.scale"
								rx="8"
								:fill="roomFill"
								:stroke="roomStroke"
								stroke-width="0"
							/>
							<circle
								v-if="'circle' === roomData.type"
								:cx="roomData.cx * roomData.scale + roomData.offsetX"
								:cy="roomData.cy * roomData.scale + roomData.offsetY"
								:r="roomData.r * roomData.scale"
								:fill="roomFill"
								:stroke="roomStroke"
								stroke-width="0"
							/>
							<polygon
								v-if="'polygon' === roomData.type"
								:points="
									roomData.points
										.map((it, idx) =>
											idx % 2 ? it * roomData.scale + roomData.offsetY : it * roomData.scale + roomData.offsetX,
										)
										.join(' ')
								"
								:fill="roomFill"
								:stroke="roomStroke"
								stroke-width="0"
							/>
						</g>
						<g style="cursor: pointer" v-for="(item, idx) in objectPins" :key="idx" @click="onSelectPin(item)">
							<path
								:transform="
									`translate(${item.x * roomData.scale + roomData.offsetX - 5},
									${item.y * roomData.scale + roomData.offsetY - 24})`
								"
								d="M12.791,12.893,15.375,4.95a3.38,3.38,0,0,0,2.377-1.734L9.878,0a3.4,3.4,0,0,0,.469,2.925l-3.7,7.458A9.32,9.32,0,0,0,0,12.955L7.479,16.01,4.12,24.234l.778.318,3.359-8.224,7.479,3.054a9.32,9.32,0,0,0-2.945-6.489"
								:fill="roomStroke"
							/>
						</g>
					</svg>
				</div>
			</div>
			<a href="javascript:void(0)" @click="onClose()">닫기</a>
		</div>
	</div>
	<!-- 팝업 -->
</template>
<script>
import { showSpinner, hideSpinner } from '@/utils/cmm';
import { sizeOfImage } from '@/utils/cmm';
import objectpin from '@/api/objectpin';
import room from '@/api/room';
import floorplan from '@/api/floorplan';

const PADDING = 10;

export default {
	props: {
		value: {},
	},
	data() {
		return {
			dialog: false,
			popupResolve: null,
			roomId: null,

			imageUrl: null,
			roomData: {},
			objectPins: null,
			pinFill: '#EA5B5B33',
			pinStroke: '#EA5B5B',
			roomFill: '#0c234c33',
			roomStroke: '#0c234c',
			strokeWidth: 1,
		};
	},
	methods: {
		onClose(value) {
			this.dialog = false;
			this.roomId = null;
			this.imageUrl = null;
			this.roomData = {};
			this.objectPins = null;

			if (this.popupResolve) {
				this.popupResolve(value);
				this.popupResolve = null;
			}
		},
		select(roomId) {
			if (this.popupResolve) {
				return Promise.reject(null);
			}

			this.roomId = roomId;
			if (!roomId) {
				return Promise.reject('roomId is null');
			}

			this.dialog = true;
			this.reload();
			return new Promise(resolve => (this.popupResolve = resolve));
		},
		onLoadImgPlan({ target }) {
			const data = this.roomData;
			const width = target.width;
			const height = target.height;
			data.imageWidth = width;
			data.imageHeight = height;
			target.width = width * data.scale;
			target.height = height * data.scale;
		},
		onSelectPin(item) {
			this.onClose({ id: item.id, objectId: item.objectId, objectName: item.objectName });
		},
		async reload() {
			try {
				showSpinner();
				let resRoom = await room.getOne(this.roomId);
				this.roomName = resRoom.name;
				const vals = resRoom.shapeInfo.split(' ').map(it => Number(it));
				let roomData = {
					id: resRoom.id,
					scale: 1,
					type: resRoom.shapeType,
					name: resRoom.name,
					ownership: resRoom.ownership,
					offsetX: 0,
					offsetY: 0,
					imageWidth: 1,
					imageHeight: 1,
				};
				switch (resRoom.shapeType) {
					case 'rect':
						Object.assign(roomData, {
							x: vals[0],
							y: vals[1],
							width: vals[2],
							height: vals[3],
							area: {
								x: vals[0],
								y: vals[1],
								width: vals[2],
								height: vals[3],
							},
						});
						break;
					case 'circle':
						Object.assign(roomData, {
							cx: vals[0],
							cy: vals[1],
							r: vals[2],
							area: {
								x: vals[0] - vals[2],
								y: vals[1] - vals[2],
								width: vals[2] * 2,
								height: vals[2] * 2,
							},
						});
						break;
					case 'polygon':
						{
							let result = [
								[vals[0], vals[0]],
								[vals[1], vals[1]],
							];
							for (let i = 2; i < vals.length; i += 2) {
								result[0][0] = Math.min(result[0][0], vals[i]);
								result[0][1] = Math.max(result[0][1], vals[i]);
								result[1][0] = Math.min(result[1][0], vals[i + 1]);
								result[1][1] = Math.max(result[1][1], vals[i + 1]);
							}
							Object.assign(roomData, {
								points: vals,
								area: {
									x: result[0][0],
									y: result[1][0],
									width: result[0][1] - result[0][0],
									height: result[1][1] - result[1][0],
								},
							});
						}
						break;
				}

				let resFloorplan = await floorplan.getOne(resRoom.floorPlanId);
				const imgSrc = `/file/${resFloorplan.imageId}`;
				const sizeImg = await sizeOfImage(imgSrc);
				roomData.imageWidth = sizeImg.width;
				roomData.imageHeight = sizeImg.height;

				const clientWidth = this.$refs.floorPlanArea.clientWidth;
				const clientHeight = this.$refs.floorPlanArea.clientHeight;

				if (roomData.area) {
					const scaleX = (clientWidth - PADDING * 2) / roomData.area.width;
					const scaleY = (clientHeight - PADDING * 2) / roomData.area.height;
					if (scaleX < scaleY) {
						const offset = (roomData.area.y + roomData.area.height * 0.5) * scaleX - clientHeight * 0.5;
						roomData.offsetX = -roomData.area.x * scaleX + PADDING;
						roomData.offsetY = -offset;
						roomData.scale = scaleX;
					} else {
						const offset = (roomData.area.x + roomData.area.width * 0.5) * scaleY - clientWidth * 0.5;
						roomData.offsetX = -offset;
						roomData.offsetY = -roomData.area.y * scaleY + PADDING;
						roomData.scale = scaleY;
					}
				} else {
					roomData.scale = Math.min(clientWidth / roomData.imageWidth, clientHeight / roomData.imageHeight);
					roomData.offsetX = (clientWidth - roomData.imageWidth * roomData.scale) * 0.5;
					roomData.offsetY = (clientHeight - roomData.imageHeight * roomData.scale) * 0.5;
				}

				let resObjectPin = await objectpin.get({ roomId: this.roomId });
				let objectPins =
					resObjectPin.data &&
					resObjectPin.data
						.filter(it => it.isUse)
						.map(it => {
							const vals = it.areaInfo.split(' ').map(val => Number(val));
							return {
								id: it.id,
								objectId: it.objectId,
								x: vals[0] + vals[2] * 0.5,
								y: vals[1] + vals[3] * 0.5,
								fileId: it.fileId,
								objectName: it.objectName,
								style: null,
							};
						});

				this.roomData = roomData;
				if (resFloorplan.imageId) {
					this.imageUrl = imgSrc;
				}
				this.objectPins = objectPins;
			} catch (ex) {
				console.error(ex);
				await this.$alert(`실 조회 실패했습니다.\r\n${ex.message}`);
			} finally {
				hideSpinner();
			}
		},
	},
};
</script>

<style scoped></style>
